
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Layout from '@kronoss/bricks/Layout/Layout';
import { Container } from '@mui/material';
import Button from '@kronoss/components/Button/Button';
import Link from 'next/link';
import { homePageRoute } from '@kronoss/utils/routes';
import styles from '@kronoss/styles/pages/404.module.scss';
import useTranslation from 'next-translate/useTranslation';
const NotFound = () => {
    const { t } = useTranslation('not-found');
    return (<Layout>
      <Container className={styles.NotFound}>
        <div className={styles.Title}>404</div>
        <div className={styles.Subtitle}>{t('title')}</div>
        <div className={styles.Description}>{t('subtitle')}</div>
        <Link href={homePageRoute} aria-label={t('actions.home')}>
          <Button aria-label={t('actions.home')} className={styles.Button}>
            {t('actions.home')}
          </Button>
        </Link>
      </Container>
    </Layout>);
};
export default NotFound;

    async function __Next_Translate__getStaticProps__193ab7df3b2__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193ab7df3b2__ as getStaticProps }
  